@font-face {
  font-display: swap;
  font-family: 'Brandon Text';
  font-weight: normal;
  font-style: normal;
  src: url(./brandon-text.eot);
  src: url(./brandon-text.eot?#iefix) format('embedded-opentype'),
    url(./brandon-text.woff2) format('woff2'),
    url(./brandon-text.woff) format('woff'),
    url(./brandon-text.ttf) format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Brandon Text';
  font-weight: normal;
  font-style: italic;
  src: url(./brandon-text-italic.eot);
  src: url(./brandon-text-italic.eot?#iefix) format('embedded-opentype'),
    url(./brandon-text-italic.woff2) format('woff2'),
    url(./brandon-text-italic.woff) format('woff'),
    url(./brandon-text-italic.ttf) format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Brandon Text';
  font-weight: normal;
  font-style: normal;
  src: url(./brandon-text-medium.eot);
  src: url(./brandon-text-medium.eot?#iefix) format('embedded-opentype'),
    url(./brandon-text-medium.woff2) format('woff2'),
    url(./brandon-text-medium.woff) format('woff'),
    url(./brandon-text-medium.ttf) format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Brandon Text';
  font-weight: normal;
  font-style: italic;
  src: url(./brandon-text-medium-italic.eot);
  src: url(./brandon-text-medium-italic.eot?#iefix) format('embedded-opentype'),
    url(./brandon-text-medium-italic.woff2) format('woff2'),
    url(./brandon-text-medium-italic.woff) format('woff'),
    url(./brandon-text-medium-italic.ttf) format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Brandon Text';
  font-weight: bold;
  font-style: normal;
  src: url(./brandon-text-bold.eot);
  src: url(./brandon-text-bold.eot?#iefix) format('embedded-opentype'),
    url(./brandon-text-bold.woff2) format('woff2'),
    url(./brandon-text-bold.woff) format('woff'),
    url(./brandon-text-bold.ttf) format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Brandon Text';
  font-weight: bold;
  font-style: italic;
  src: url(./brandon-text-bold-italic.eot);
  src: url(./brandon-text-bold-italic.eot?#iefix) format('embedded-opentype'),
    url(./brandon-text-bold-italic.woff2) format('woff2'),
    url(./brandon-text-bold-italic.woff) format('woff'),
    url(./brandon-text-bold-italic.ttf) format('truetype');
}
