.container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: 100hv;
  width: 100%;
}

body {
  font-family: 'Brandon Text';
  font-size: 17px;
  background-image: url(../../images/background.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}

* {
  box-sizing: border-box;
}

.logo {
  width: 190px;
  padding-left: 20px;
}

@media (max-width: 600px) {
  .logo {
    width: 150px;
  }
}

.divForm {
  color: black;
  width: 40%;
  min-width: 370px;
  padding: 30px;
  margin-top: 5%;
  background-color: #efefef;
  border-radius: 6px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
}

.mailSignature {
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 5%;
}

.copyBox {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 700px;
  height: 640px;
  border-radius: 6px;
  max-width: 100vh;
  box-sizing: border-box;
}

.signatureTable {
  padding: 20px;
}

.alert {
  box-sizing: border-box;
  background-color: rgb(223, 240, 216);
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.form-element {
  margin-top: 10px;
  margin-bottom: 20px;
}

input {
  padding: 7px;
}

input,
input::-webkit-input-placeholder {
  font-size: 15px;
  line-height: normal;
}

small {
  color: #737373;
}

.checkbox {
  margin-top: 10px;
  user-select: none;
}

.checkbox label {
  cursor: pointer;
}

input[type="checkbox"] {
  margin-right: 0.5em;
  cursor: pointer;
}

.radio {
  margin-top: 10px;
}

.form-control {
  height: 44px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid #c4c2c2;
  font-size: 18px;
  padding: 1px calc(0.8rem + 1px);
}

input[type='text']:focus {
  background-color: #fff;
  border: 2px solid #0b8bd7;
  outline: 0;
}

.copy {
  font-family: 'Brandon Text', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 600;
  background-color: rgb(146, 1, 98);
  color: rgb(255, 255, 255);
  cursor: pointer;
  padding: 6px 48px;
  border-radius: 6px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  transition: all 0.15s ease-out 0s;
  outline: 0px;
  margin-top: 50px;
}

.labelInput {
  color: #343434;
  font-weight: 600;
  font-size: 1.125em;
  display: block;
  margin-bottom: 4px;
  margin-left: 1px;
}

.labelInput.large {
  font-size: 1.5em;
}

.butonContainer {
  display: flex;
  justify-content: center;
}

.title {
  text-align: center;
  margin-left: 20px;
  color: rgb(142, 185, 39);
  padding-left: 20px;
}

.page-header {
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1200px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .copyBox {
    width: 370px;
    height: unset;
    padding: 20px 5px;
  }

  .butonContainer {
    margin-bottom: 20px;
  }

  .title {
    font-size: 24px;
    padding-left: 0;
  }

  .page-header {
    flex-direction: column;
  }
}
